import { Route, Routes, useLocation } from 'react-router-dom';
import React, { useState, useEffect , useRef } from "react";
import ScrollToTop from './pages/ScrollToTop';
import './App.css';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Navbar from './navbar-footer-component/Navbar';
import Footer from './navbar-footer-component/Footer';
import Tshirts from './pages/Tshirts';
import SignIn from './pages/Signin-Signup-pages/SignIn';
import ResetPassword from './pages/Signin-Signup-pages/ResetPassword';
import SignUp from './pages/Signin-Signup-pages/SignUp';
import ForgotPassword from './pages/Signin-Signup-pages/ForgotPassword';
import SingleProductDetails from './pages-inner-components/data_components/SingleProductDetails';
import Contact from './pages/Contact';
import Wishlist from './pages/Wishlist';
import MyAccout from './pages/MyAccout';
import Cart from './pages/Cart';
import CheckOut from './pages/CheckOut';
import Shorts from './pages/Shorts';
import Lowers from './pages/Lowers';
import More from './pages/More';
// import Tracks from './pages/Tracks';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ShippingPolicy from './pages/ShippingPolicy';
import ExchangePolicy from './pages/ExchangePolicy';
import SearchResults from './pages/SearchResults';
import About from './pages/About';
import OrderSuccessfull from './pages/OrderSuccessfull';
import LowersCollections from './pages-inner-components/LowersCollections';
import TshirtsCollections from './pages-inner-components/TshirtsCollections';
import ShortsCollections from './pages-inner-components/ShortsCollections';
import SimilarProductsCarousel from './pages-inner-components/SimilarProductsCarousel';
import BlogPostPage from './pages/BlogPostPage';
// import MaintenanceBanner from './pages/MaintenanceBanner';


function App() {

  const location = useLocation();
  const hiddenRoutes = ['/signin', '/signup', '/forgotpassword', '/resetpassword/:token', 'resetpassword'];

  const shouldDisplayNavbarAndFooter = !hiddenRoutes.some(route => location.pathname.includes(route));
  function checkTokenExpiration() {
    const token = localStorage.getItem('token'); // Replace 'yourJWTTokenKey' with the actual key you use to store the token
  
    if (token) {
      const tokenData = JSON.parse(atob(token.split('.')[1])); // Decoding the token
      const tokenExpirationTime = tokenData.exp * 1000; // Convert expiration time to milliseconds
  
      if (Date.now() >= tokenExpirationTime) {
        // Token is expired, remove it
        localStorage.removeItem('token'); // Remove the token key from storage
      }
    }
  }
  
useEffect(() => {
    checkTokenExpiration();
  }, []);
  return (
    <div className="App">
      {shouldDisplayNavbarAndFooter && <Navbar />}
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='/' element={<MaintenanceBanner />} /> */}
        <Route path='/about-us' element={<About />} />
        <Route path='/tshirts' element={<Tshirts />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/signin' element={<SignIn />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/wishlist' element={<Wishlist />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} />

        <Route path='/tshirts/:productSlug/:productId' element={<SingleProductDetails />} />
        <Route path='/more/:productSlug/:productId' element={<SingleProductDetails />} />

        <Route path='/lowers/:productSlug/:productId' element={<SingleProductDetails />} />
        <Route path='/shorts/:productSlug/:productId' element={<SingleProductDetails />} />
        <Route path='/tshirts/product/:productSlug/:id' element={<SingleProductDetails />} />
        <Route path="/products/:productSlug/:productId" element={<SingleProductDetails />} />

        <Route path='/blog' element={<Blog />} />

        <Route path='/shorts' element={<Shorts />} />
        <Route path='/lowers' element={<Lowers />} />
        <Route path='/more' element={<More />} />
        {/* <Route path='/tracks' element={<Tracks />} /> */}
        <Route path='/cart' element={<Cart />} />
        <Route path='/checkout' element={<CheckOut />} />
        <Route path='/myaccount' element={<MyAccout />} />
        <Route path='/terms-and-conditions' element={<TermsConditions />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/shipping-policy' element={<ShippingPolicy />} />
        <Route path='/exchange-policy' element={<ExchangePolicy />} />
        <Route path="/search-results" element={<SearchResults />} />
        <Route path='/ordersuccess' element={<OrderSuccessfull />} />
        <Route path='/lowerscollections' element={<LowersCollections />} />
        <Route path='/tshirtscollections' element={<TshirtsCollections />} />
        <Route path='/shortscollections' element={<ShortsCollections />} />
        <Route path="/blog/:id" element={<BlogPostPage />} />


        <Route path='/Carousel' element={<SimilarProductsCarousel />} />

      </Routes>
      {shouldDisplayNavbarAndFooter && <Footer />}
    </div>
  );
}

export default App;


